@charset "utf-8";

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "page",
        "icons"
        // "pricing_table"
;


#privacy-section {
  h1 {
    margin-bottom: 1.5rem;
  }

  h4 {
    margin-top: 2rem;
  }

  h6 {
    font-weight: bold;
    margin-top: 1.8rem;
  }

  p {
    margin-top: 1rem;
  }
  
  #data-owner {
    background: #eee;
    border: 1px solid #333;
    padding-top: 1rem;

    .data-owner-section {
      padding: 0 1.5rem;
      border-bottom: 1px solid #333;
    }

    .data-owner-section:last-child {
      border-bottom: none;
    }

  }
}